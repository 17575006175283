import { useState, useEffect } from "react";
import axios from "axios";

export function useApi(url, defaultValue = [], token = null) {
  const [data, setData] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    const headers = token && { Authorization: `${token}` };
    axios
      .get(url, { headers })
      .then((response) => setData(response.data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [url, token]); 

  return { data, loading, error };
}
