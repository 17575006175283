import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { notification } from "antd";
import ResponsiveDrawer from "../components/ResponsiveDrawer/ResponsiveDrawer";

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useContext(AuthContext);

  const showDrawer = location.pathname !== "/";

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    navigate("/");
  };

  const getUserById = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_MAIN}/users/${userId}`
      );
      return response.data;
    } catch (error) {
      notification.error({
        message: "Erro ao encontrar o usuário!",
        description: error.toString(),
      });
    }
  };

  const getUserData = async (userId) => {
    const userData = await getUserById(userId);
    setUser(userData);
  };

  const checkTokenValidity = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      handleLogout();
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const isExpired = decodedToken.exp * 1000 < Date.now();
      if (isExpired) {
        handleLogout();
      }
    } catch (error) {
      handleLogout();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenValidity();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token && typeof token === "string") {
      checkTokenValidity();
      const decodedToken = jwtDecode(token);
      getUserData(decodedToken.userId);
    }
  }, [location]);

  return (
    <>
      {showDrawer && <ResponsiveDrawer />}
      {children}
    </>
  );
};

export default AuthLayout;
