import styled, { css } from "styled-components";

export const CondominiumCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 250px;
  width: 360px;
  text-align: center;
  box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  padding: 1.5rem 1.5rem;
  gap: 10px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 3px solid #dfe3e7;
    `}
`;
