import styled from "styled-components";

export const CondoRoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1b6684;
  border-radius: 10px;
  padding: 20px;
  gap: 20px;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.21);

  @media (max-width: 650px) {
    width: 320px;
  }
`;

export const RolesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
`;

export const AccordionContainer = styled.div`
  width: 500px;

  @media (max-width: 650px) {
    width: 280px;
  }
`
