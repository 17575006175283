import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Checkbox,
  Button,
  Spin,
  Switch,
  Typography,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import axios from "axios";
import { notification } from "antd";

const UserModal = ({ condoId, roles, onClose, user, onOpen, fetchUsers }) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = async () => {
    const currentFormValues = form.getFieldsValue();
    console.log("🚀 ~ handleSubmit ~ currentFormValues:", currentFormValues);

    setIsLoading(true);
    if ("" === currentFormValues.email) {
      notification.error({
        message: "Erro!",
        description: "Por favor, insira um email.",
      });
      setIsLoading(false);
      return;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(currentFormValues.email)) {
      notification.error({
        message: "Erro!",
        description: "Por favor, insira um email válido.",
      });
      setIsLoading(false);
      return;
    }

    if ("" === currentFormValues.password) {
      notification.error({
        message: "Erro!",
        description: "Por favor, insira sua senha.",
      });
      setIsLoading(false);
      return;
    }

    const activeRoles = Object.keys(currentFormValues)
      .filter(
        (key) => key.startsWith("role-") && currentFormValues[key] === true
      )
      .map((key) => {
        const roleId = key.replace("role-", "");
        return {
          role: roleId,
          isActive: true,
        };
      });

    let data = {};

    if (isExistingUser) {
      data = {
        email: currentFormValues.email,
        userCreator: user._id,
        condoRoles: [
          {
            condo: condoId,
            roles: activeRoles,
          },
        ],
      };
    } else {
      data = {
        name: currentFormValues.name,
        email: currentFormValues.email,
        password: currentFormValues.password,
        userCreator: user._id,
        condoRoles: [
          {
            condo: condoId,
            roles: activeRoles,
          },
        ],
      };
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_MAIN}/users`, data);
      notification.success({
        message: "Sucesso!",
        description: "Usuário adicionado ao condomínio.",
      });
      form.resetFields();
      handleClose();
      fetchUsers();
    } catch (error) {
      notification.error({
        message: "Erro!",
        description: `${error.response.data}`,
      });
    } finally {
      setIsLoading(false);
    }
    onClose();
  };
  return (
    <Modal
      title="Adicionar novo usuário ao condominio"
      open={onOpen}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleSubmit}
        >
          Adicionar
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <Switch onChange={() => setIsExistingUser(!isExistingUser)} />
          <Typography>Usúario já possui login e senha</Typography>
        </div>
        {!isExistingUser && (
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: "Por favor, insira o nome!" }]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Por favor, insira o email!",
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {!isExistingUser && (
          <Form.Item
            label="Senha"
            name="password"
            rules={[{ required: true, message: "Por favor, insira a senha!" }]}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        )}
        {roles.map((role) => (
          <Form.Item
            key={role._id}
            name={`role-${role._id}`}
            valuePropName="checked"
          >
            <Checkbox>{role.name}</Checkbox>
          </Form.Item>
        ))}
      </Form>
      {isLoading && <Spin />}
    </Modal>
  );
};

export default UserModal;
