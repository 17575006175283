import React, { createContext, useContext, useState, useCallback } from "react";

const DocumentsContext = createContext();

export const useDocuments = () => useContext(DocumentsContext);

export const DocumentsProvider = ({ children }) => {
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const requestDocumentListUpdate = useCallback(() => {
    setTriggerUpdate((prev) => !prev); 
  }, []);

  return (
    <DocumentsContext.Provider
      value={{ triggerUpdate, requestDocumentListUpdate }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};
