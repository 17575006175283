import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

const ConfirmationModal = ({ open, onClose, onConfirm, dialogText }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle
        id="confirmation-dialog-title"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "5px"
        }}
      >
        <Delete />
        {"Confirmar exclusão"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {dialogText}

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
          autoFocus
        >
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
