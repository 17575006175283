import {
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { CondominiumCardContainer } from "./styled";
import { useState } from "react";
import { Apartment, EditRounded, MoreVert } from "@mui/icons-material";
import axios from "axios";
import { notification } from "antd";
import CondominiumModal from "../CondominiumModal/CondominiumModal";

export const CondominiumCard = ({
  condominium,
  setSelectedCondominiumsIds,
  selectedCondominiumsIds,
  onUpdate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCondo, setSelectedCondo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState("");

  const isSelected = selectedCondominiumsIds?.includes(condominium._id);

  const handleToggleSelect = (event) => {
    if (isSelected) {
      setSelectedCondominiumsIds(
        selectedCondominiumsIds.filter((id) => id !== condominium._id)
      );
    } else {
      setSelectedCondominiumsIds([...selectedCondominiumsIds, condominium._id]);
    }
  };

  const handleDoubleClick = (condominium) => {
    setSelectedCondo(condominium);
    setIsModalOpen(true);
  };

  const handleUpdateCondominium = async (formData) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_MAIN}/condominiums/${formData._id}`,
        formData
      );
      notification.success({
        message: "Condomínio atualizado com sucesso!",
      });
      onUpdate(response.data);
    } catch (error) {
      notification.error({
        message: "Erro ao atualizar condomínio",
        description: `${error.response.data.message}`,
      });
    }
  };

  return (
    <CondominiumCardContainer isSelected={isSelected}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "10px",
          cursor: "pointer"
        }}
        onDoubleClick={() => handleDoubleClick(condominium)}
      >
        <Apartment color="action" />
        <Typography style={{ color: "#1b6684" }}>{condominium.name}</Typography>
        <IconButton
          size="large"
          edge="end"
          style={{ cursor: "pointer" }}
          aria-label="condominium"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={(event) => {
            event.stopPropagation();
            setSelectedCondo(condominium);
            setAnchorEl(event.currentTarget);
          }}
          color="inherit"
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              handleToggleSelect();
            }}
          >
            <ListItemIcon>
              <EditRounded fontSize="small" />
            </ListItemIcon>
            Selecionar
          </MenuItem>
        </Menu>
      </div>

      <TextField
        variant="standard"
        fullWidth
        disabled
        value={condominium.cnpj}
        label="CNPJ"
      />
      <TextField
        variant="standard"
        fullWidth
        disabled
        value={condominium.address}
        label="Endereço"
      />
      <CondominiumModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleUpdateCondominium}
        initialValues={selectedCondo}
      />
    </CondominiumCardContainer>
  );
};

export default CondominiumCard;
