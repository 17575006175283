import React, { useState } from "react";
import { Table, Button } from "antd";
import UserEditModal from "../UserEditModal/UserEditModal";
import { useApi } from "../../hooks/useApi";

export const UsersTable = ({
  users,
  setCondoId,
  loading,
  condo,
  fetchUsers,
}) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { data: roles } = useApi(`${process.env.REACT_APP_API_MAIN}/roles`);

  const showModal = (user) => {
    setCurrentUser(user);
    setOpenModal(true);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Funções",
      key: "roles",
      render: (_, record) => (
        <span>
          {Array.isArray(record.condoRoles)
            ? record.condoRoles.flatMap((cr) => cr.roles.role.name).join(", ")
            : "N/A"}
        </span>
      ),
    },
    {
      title: "Ação",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => showModal(record)}>Editar</Button>
      ),
    },
  ];

  return (
    <>
      <Table
        style={{ width: "80%" }}
        columns={columns}
        dataSource={users}
        rowKey="_id"
        loading={loading}
      />

      <UserEditModal
        open={openModal}
        condoId={condo}
        roles={roles}
        fetchUsers={fetchUsers}
        user={currentUser}
        onClose={handleCancel}
      />
    </>
  );
};

export default UsersTable;
