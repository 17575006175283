import { createContext, useState, useContext } from "react";

const DrawerContext = createContext();

export const useDrawerContext = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }) => {
  const [drawerExpanded, setDrawerExpanded] = useState(true);

  return (
    <DrawerContext.Provider value={{ drawerExpanded, setDrawerExpanded }}>
      {children}
    </DrawerContext.Provider>
  );
};
