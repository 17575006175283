import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Navbar from "../../components/NavBar/NavBar";
import { ContentContainer, PageContainer, ProfileContainer } from "./styled";
import { green } from "@mui/material/colors";
import { Person } from "@mui/icons-material";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CondoRoleCard } from "../../components/CondoRoleCard/CondoRoleCard";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import axios from "axios";
import { useDrawerContext } from "../../context/DrawerContext";

export const Profile = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const drawerWidth = 200;
  const miniDrawerWidth = 100;
  const { drawerExpanded } = useDrawerContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const drawerMargin = drawerExpanded ? drawerWidth : miniDrawerWidth;

  const [formData, setFormData] = useState({
    name: user?.name,
    email: user?.email,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_MAIN}/users/${user._id}`,
        formData
      );
    } catch (error) {
      notification.error({
        message: "Erro!",
        description: error.response.data,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        marginTop: "6rem",
        width: isMobile ? "100%" : `calc(100% - ${drawerMargin}px)`,
        overflowX: "hidden",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: !isMobile && drawerMargin,
      }}
    >
      <ProfileContainer>
        <Navbar />
        <PageContainer>
          <ContentContainer>
            <Avatar sx={{ bgcolor: green[500], width: 104, height: 104 }}>
              <Person sx={{ width: 84, height: 64 }} />
            </Avatar>
            <Typography variant="h5">
              Olá, <strong>{user?.name.split(" ")[0]}</strong>
            </Typography>
            <FormControl
              variant="outlined"
              style={{ minWidth: isMobile ? 250 : 500 }}
            >
              <TextField
                name="name"
                label="Nome"
                value={formData.name}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              style={{ minWidth: isMobile ? 250 : 500 }}
            >
              <TextField
                name="email"
                label="Email"
                value={formData.email}
                disabled
                onChange={handleInputChange}
              />
            </FormControl>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Button variant="contained" onClick={handleSubmit}>
                {isLoading ? (
                  <CircularProgress size={24} color="info" />
                ) : (
                  "Salvar"
                )}
              </Button>
              <Button variant="outlined" color="error" onClick={handleLogout}>
                Sair
              </Button>
            </div>
          </ContentContainer>
          {/* <ContentContainer>
            <CondoRoleCard user={user} />
          </ContentContainer> */}
        </PageContainer>
      </ProfileContainer>
    </div>
  );
};

export default Profile;
