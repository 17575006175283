import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputContainer, MainContainer, TitleContainer } from "./styled";
import axios from "axios";
import { notification } from "antd";
import { AuthContext } from "../../context/AuthContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import whitelogo from "../../assets/logos/whitelogo.png";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { setEmail } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onButtonClick = async () => {
    setEmailError("");
    setPasswordError("");

    if ("" === emailInput) {
      setEmailError("Por favor, insira seu email.");
      return;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emailInput)) {
      setEmailError("Por favor, insira um email válido.");
      return;
    }

    if ("" === password) {
      setPasswordError("Por favor, insira sua senha!");
      return;
    }

    // if (password.length < 7) {
    //   setPasswordError("The password must be 8 characters or longer");
    //   return;
    // }

    try {
      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_MAIN}/login`,
        {
          email: emailInput,
          password,
        }
      );
      const { token } = response.data;

      localStorage.setItem("token", token);
      navigate("/home");
      setEmail(emailInput);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notification.error({
          message: "Login Failed",
          description: "Email ou senha inválido.",
        });
      } else {
        notification.error({
          message: "Error",
          description: "Ocorreu um erro! Por favor, tente novamente.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (loggedIn) {
  //     navigate("/home");
  //   }
  // }, [loggedIn, navigate]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onButtonClick();
    }
  };

  return (
    <div onKeyDown={handleKeyDown}>
      <MainContainer>
        <TitleContainer>
          <img width={isMobile ? 200 : 500} src={whitelogo} alt="whitelogo" />
        </TitleContainer>
        <br />
        <InputContainer>
          <TextField
            fullWidth
            value={emailInput}
            required={true}
            label="Email"
            variant="outlined"
            placeholder="Insira seu email"
            onChange={(ev) => setEmailInput(ev.target.value)}
            helperText={emailError}
            FormHelperTextProps={{ sx: { color: "red" } }}
          />
        </InputContainer>
        <br />
        <InputContainer>
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            required={true}
            value={password}
            label="Senha"
            variant="outlined"
            placeholder="Insira sua senha"
            onChange={(ev) => setPassword(ev.target.value)}
            helperText={passwordError}
            FormHelperTextProps={{ sx: { color: "red" } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputContainer>
        <br />
        <InputContainer>
          <Button
            variant="contained"
            onClick={onButtonClick}
            disabled={loading}
            style={{ background: "#1B6684", cursor: "pointer" }}
            fullWidth
            size="large"
          >
            {" "}
            {loading ? <CircularProgress size={24} /> : "ENTRAR"}
          </Button>
        </InputContainer>
      </MainContainer>
    </div>
  );
};

export default Login;
