import React, { useContext, useEffect, useMemo, useState } from "react";
import { UsersTable } from "../../components/UsersTable/UsersTable";
import axios from "axios";
import {
  Alert,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDrawerContext } from "../../context/DrawerContext";
import UserModal from "../../components/ProfileUserModal/UserModal";
import { AuthContext } from "../../context/AuthContext";
import { Add } from "@mui/icons-material";
import { useApi } from "../../hooks/useApi";
import { Select, Typography as TypographyAntd } from "antd";
import { FilterCondominium } from "../../components/UsersTable/styled";
const { Title } = TypographyAntd;

export const Users = () => {
  const [condoId, setCondoId] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { drawerExpanded } = useDrawerContext();
  const { user } = useContext(AuthContext);
  const { data: roles } = useApi(`${process.env.REACT_APP_API_MAIN}/roles`);
  const { data: condominiums } = useApi(
    `${process.env.REACT_APP_API_MAIN}/condominiums`
  );
  const [userCondominiums, setUserCondominiums] = useState([]);
  const [selectedCondoId, setSelectedCondoId] = useState(undefined);

  useEffect(() => {
    if (user && user.condoRoles) {
      const condos = user.condoRoles
        .map((condoRole) => ({
          _id: condoRole.condo?._id,
          name: condoRole.condo?.name,
        }))
        .sort((a, b) => a?.name?.localeCompare(b.name));
      setUserCondominiums(condos);
      if (condos.length > 0) {
        const firstCondoId = condos[0]._id;
        setSelectedCondoId(firstCondoId);
        setCondoId(firstCondoId);
      }
    }
  }, [user]);

  const sortedCondominiums = useMemo(() => {
    return condominiums
      ? [...condominiums].sort((a, b) => a.name.localeCompare(b.name))
      : [];
  }, [condominiums]);

  const getRoles = user?.condoRoles.filter(
    (cr) => cr.condo?._id === selectedCondoId
  )[0];

  const isAdminOrSind = getRoles?.roles.some(
    (r) => r.role.name === "Síndico" || r.role.name === "Administrador"
  );

  const drawerWidth = 200;
  const miniDrawerWidth = 100;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const drawerMargin = drawerExpanded ? drawerWidth : miniDrawerWidth;

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const endpoint = `${process.env.REACT_APP_API_MAIN}/users/condo/${
        condoId || 0
      }`;
      const response = await axios.get(endpoint);
      const data = await response.data.users;
      setUsers(data || []);
    } catch (error) {
      console.error("Falha ao buscar usuários:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (condoId) {
      fetchUsers();
    }
  }, [condoId]);

  const handleCondoChange = (value) => {
    setSelectedCondoId(value);
    setCondoId(value);
  };

  return (
    <div
      style={{
        display: "flex",
        marginTop: "6rem",
        width: isMobile ? "100%" : `calc(100% - ${drawerMargin}px)`,
        overflowX: "hidden",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: !isMobile && drawerMargin,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: "90%",
          marginTop: isMobile && "30px",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h5">Usuários</Typography>
      </div>
      <FilterCondominium>
        <Title level={5}>Selecione o condomínio</Title>
        <Select
          placeholder="Selecione o condomínio"
          style={{ width: 300, marginBottom: 16 }}
          onChange={handleCondoChange}
          value={selectedCondoId}
        >
          {(user && user?.isMaster ? sortedCondominiums : userCondominiums).map(
            (condo) => (
              <Select.Option key={condo._id} value={condo._id}>
                {condo.name}
              </Select.Option>
            )
          )}
        </Select>
      </FilterCondominium>

      {isAdminOrSind || (user && user.isMaster) ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "flex-start",
              marginLeft: "8.5%",
              marginBottom: "20px",
            }}
          >
            <Button
              startIcon={<Add />}
              variant="contained"
              style={{
                background: "#1b7884",
                height: "50px",
                marginLeft: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                color: "#FFFBF0",
              }}
              onClick={() => setIsModalOpen(true)}
            >
              Adicionar
            </Button>
          </div>

          <UsersTable
            users={users}
            setCondoId={setCondoId}
            loading={isLoading}
            condo={condoId}
            fetchUsers={fetchUsers}
          />
        </>
      ) : (
        <Alert severity="error" style={{ width: "90%" }}>
          Você não tem permissão para visualizar os usários desse condomínio.
          Solicite ao Administrador ou Síndico.
        </Alert>
      )}

      <UserModal
        condoId={condoId}
        user={user}
        roles={roles}
        onOpen={isModalOpen}
        fetchUsers={fetchUsers}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Users;
