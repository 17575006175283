import { useMemo } from 'react';

const useSelectOptions = (documents, key) => {
  return useMemo(() => {
    const allValues = documents.reduce((acc, document) => {
      if (document[key] && document[key].length) {
        acc.push(...document[key]);
      }
      return acc;
    }, []);

    const uniqueValues = [...new Set(allValues)];

    const limitedValues = uniqueValues.slice(0, 15);

    return limitedValues.map(value => ({
      label: value,
      value: value,
    }));
  }, [documents, key]);
};

export default useSelectOptions;
