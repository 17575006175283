import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    @media (max-width: 768px) {
      display: inline-flex !important;
      flex-direction: column !important;
      display: none;

    }
  }
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    @media (max-width: 768px) {
  visibility: visible !important;
    }
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  @media (max-width: 768px) {
  visibility: visible !important;
  }
}

.ant-picker-panels > *:nth-child(2) {
  @media (max-width: 768px) {
      display: none;
  }
    }

.ant-picker-panel-container, .ant-picker-footer {
  @media (max-width: 768px) {
  width: 280px !important;
  }
}

.ant-picker-footer-extra > div {
  @media (max-width: 768px) {
  flex-wrap: wrap !important; 
  }
}
`;

export const UserContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: center;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 50px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentNavBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
