import styled, { css } from "styled-components";

export const DocumentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dfe3e7;
  justify-content: flex-start;
  min-height: 300px;
  width: 300px;
  text-align: center;
  box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  padding: 1.5rem 0.5rem;
  gap: 10px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #b0bec5;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-left: 8px;
`;

export const TitleText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  font-weight: bold;
  flex-grow: 1;
`;

export const PreviewContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 200px;
  display: flex;
  background-color: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  margin: 0.5rem 0;
  border-radius: 5px;
  overflow: hidden;

  img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center bottom;
    transform: translateY(25%);
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
  font-size: 0.7rem;
`;
