import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Typography, Checkbox, notification } from "antd";
import axios from "axios";

function UserEditModal({
  open,
  onClose,
  onSubmit,
  user,
  roles,
  condoId,
  fetchUsers,
}) {
  const [form] = Form.useForm();
  const [modalOpenTime, setModalOpenTime] = useState(Date.now());
  const [existingUserCondoRoles, setExistingUserCondoRoles] = useState([]);

  const fetchCurrentUserData = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_MAIN}/users/${userId}`
      );
      const otherCondoRoles = response.data.condoRoles.filter(
        (cr) => cr.condo._id !== condoId
      );
      setExistingUserCondoRoles(otherCondoRoles);
    } catch (error) {
      console.error("Erro ao buscar dados do usuário:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (user) {
      fetchCurrentUserData(user._id);
    }
  }, [user]);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (open) {
      setModalOpenTime(Date.now());
    }
  }, [open]);

  useEffect(() => {
    if (open && user) {
      const initialValues = {
        name: user.name,
        email: user.email,
      };

      user.condoRoles.forEach((cr) => {
        initialValues[`role-${cr.roles.role._id}`] = true;
      });

      form.setFieldsValue(initialValues);
    } else if (!open) {
      form.resetFields();
    }
  }, [open, user, form, modalOpenTime]);

  const handleSubmit = async () => {
    const currentFormValues = form.getFieldsValue();

    const activeRoles = Object.keys(currentFormValues)
      .filter(
        (key) => key.startsWith("role-") && currentFormValues[key] === true
      )
      .map((key) => {
        const roleId = key.replace("role-", "");
        return {
          role: roleId,
          isActive: true,
        };
      });

    const data = {
      name: currentFormValues.name,
      email: currentFormValues.email,
      userCreator: user._id,
      condoRoles: [
        ...existingUserCondoRoles,
        {
          condo: condoId,
          roles: activeRoles,
        },
      ],
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_API_MAIN}/users/${user._id}`,
        data
      );
      notification.success({
        message: "Usuário atualizado!",
      });
      form.resetFields();
      handleClose();
      fetchUsers();
    } catch (error) {
      notification.error({
        message: "Erro!",
        description: error.response.data,
      });
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Editar usuário"
      visible={open}
      onOk={handleSubmit}
      onCancel={(e) => {
        e.stopPropagation();
        onClose();
        form.resetFields();
      }}
      okText="Salvar"
      cancelText="Cancelar"
    >
      <Form form={form} layout="vertical" name="userEditForm">
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, message: "Por favor, insira o nome!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Por favor, insira o email!" }]}
        >
          <Input />
        </Form.Item>
        {user?.condoRoles.map((cr, index) => (
          <Typography component={"span"} key={cr.roles.role._id}>
            <div
              key={cr.roles.role._id}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <strong>Função {index + 1}: </strong>
              {cr.roles.role.name}
              <span style={{ color: cr.roles.role.isActive ? "green" : "red" }}>
                {cr.roles.role.isActive ? " Ativo" : " Inativo"}
              </span>
            </div>
          </Typography>
        ))}
        {roles.map((role) => (
          <Form.Item
            key={role._id}
            name={`role-${role._id}`}
            valuePropName="checked"
            style={{ marginTop: "20px" }}
          >
            <Checkbox>{role.name}</Checkbox>
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
}

export default UserEditModal;
