import {
  DetectDocumentTextCommand,
  TextractClient,
} from "@aws-sdk/client-textract";
import { notification } from "antd";
import { PDFDocument } from "pdf-lib";
import { checkOCRText } from "./checkOCRText";

export const textractOCR = async (file) => {
  try {
    const pdfDoc = await PDFDocument.load(await file.arrayBuffer(), {
      ignoreEncryption: true,
    });
    const newPdfDoc = await PDFDocument.create();

    const [firstPage] = await newPdfDoc.copyPages(pdfDoc, [0]);
    newPdfDoc.addPage(firstPage);

    const pdfBytes = await newPdfDoc.save();

    const textractClient = new TextractClient({
      region: "us-east-1",
      credentials: {
        accessKeyId: "AKIA35EYFRRCLXSFWPUM",
        secretAccessKey: "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",
      },
    });

    const textractCommand = new DetectDocumentTextCommand({
      Document: {
        Bytes: pdfBytes,
      },
    });

    const textractResponse = await textractClient.send(textractCommand);
    const extractedText = textractResponse.Blocks.map((item) => item.Text).join(
      "\n"
    );
    const result = checkOCRText(extractedText);
    return result;
  } catch (error) {
    console.error("Error processing document:", error);
    notification.error({
      message: "Erro ao processar o documento!",
      description:
        "Não foi possível processar o documento. Por favor, tente novamente.",
    });
  }
};
