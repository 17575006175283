import { useState, useEffect } from "react";
import axios from "axios";

export function useDocumentFieldOptions(url, field, token = null) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const headers = token ? { Authorization: `${token}` } : {};
    axios
      .get(url, { headers })
      .then((response) => {
        const documents = response.data.documents;
        const allValues = documents.reduce((acc, document) => {
          const values = document[field];
          if (values && values.length) {
            acc.push(...values);
          }
          return acc;
        }, []);

        const uniqueValues = Array.from(new Set(allValues));
        setOptions(uniqueValues);
      })
      .catch((error) => {
        console.error(`Erro ao buscar ${field}:`, error);
      });
  }, [url, field, token]);

  return options;
}
