import styled from "styled-components";

export const CondominiumContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  margin-bottom: 100px;
  gap: 1.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  margin-left: 8.5%;
  margin-top: 10px;
`;
