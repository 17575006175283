import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
} from "@mui/material";
import {
  DocumentCardContainer,
  TitleContainer,
  PreviewContainer,
  InfoContainer,
  TitleText,
} from "./styled";
import { useState } from "react";
import { notification } from "antd";
import {
  AccountCircle,
  Article,
  Circle,
  CloudDownload,
  EditRounded,
  MoreVert,
  PictureAsPdf,
} from "@mui/icons-material";
import axios from "axios";
import React from "react";
import { formatDate } from "../../utils/formatDate";
import { pdfjs } from "react-pdf";
import EditDocumentModal from "../EditDocumentModal/EditDocumentModal";
import { useDocuments } from "../../context/DocumentsContext";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const DocumentCard = React.memo(
  ({
    document,
    loading,
    types,
    topics,
    onUpdate,
    selectedDocumentIds,
    setSelectedDocumentIds,
  }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const token = localStorage.getItem("token");
    const { requestDocumentListUpdate } = useDocuments();

    const isSelected = selectedDocumentIds?.includes(document._id);
    const isOpenMenu = Boolean(anchorEl);

    const handleDoubleClick = () => {
      setSelectedDocument(document);
      setIsModalOpen(true);
    };

    const handleToggleSelect = (event) => {
      if (isOpenMenu) {
        event.stopPropagation();
        return;
      }

      if (isSelected) {
        setSelectedDocumentIds(
          selectedDocumentIds.filter((id) => id !== document._id)
        );
      } else {
        setSelectedDocumentIds([...selectedDocumentIds, document._id]);
      }
    };

    const handleOpenModal = (document) => {
      setSelectedDocument(document);
      setIsModalOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
      setSelectedDocument(null);
    };

    const handleSaveDocument = async (formData) => {
      setIsSaving(true);
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_MAIN}/documents/${selectedDocument._id}`,
          formData,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const updatedDocument = response.data;
        requestDocumentListUpdate();
        onUpdate(updatedDocument);
        handleCloseModal();
      } catch (error) {
        if (error.response && error.response.status === 403) {
          notification.error({
            message: "Erro ao editar documento.",
            description: `${error.response.data.message}`,
          });
        } else {
          notification.error({
            message: "Error",
            description: "Ocorreu um erro! Por favor, tente novamente.",
          });
        }
      } finally {
        setIsSaving(false);
      }
    };

    const handleDownloadDocument = (documentUrl) => {
      if (!documentUrl) {
        notification.error({
          message: "Erro",
          description: "URL do documento não disponível para download.",
        });
        return;
      }

      window.open(documentUrl, "_blank");
    };

    return (
      <>
        {loading ? (
          <DocumentCardContainer>
            <Skeleton
              variant="rectangular"
              width={100}
              height={100}
              animation="wave"
            />
            <TitleContainer>
              <Skeleton variant="text" width="100%" animation="wave" />
              <Skeleton variant="text" width="100%" animation="wave" />
              <Skeleton variant="text" width="100%" animation="wave" />
            </TitleContainer>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={56}
              animation="wave"
            />
          </DocumentCardContainer>
        ) : (
          <>
            <DocumentCardContainer isSelected={isSelected}>
              <TitleContainer onClick={(event) => handleToggleSelect(event)}>
                <PictureAsPdf color="warning" />
                <TitleText>{document.title}</TitleText>
                <IconButton
                  size="large"
                  edge="end"
                  style={{ cursor: "pointer" }}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={(event) => {
                    event.stopPropagation();
                    setSelectedDocument(document);
                    setAnchorEl(event.currentTarget);
                  }}
                  color="inherit"
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      handleOpenModal(document);
                    }}
                  >
                    <ListItemIcon>
                      <EditRounded fontSize="small" />
                    </ListItemIcon>
                    Selecionar
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleDownloadDocument(selectedDocument.document)
                    }
                  >
                    <ListItemIcon>
                      <CloudDownload fontSize="small" />
                    </ListItemIcon>
                    Download
                  </MenuItem>
                </Menu>
              </TitleContainer>
              <PreviewContainer onDoubleClick={handleDoubleClick}>
                {document.page_image_url ? (
                  <img
                    src={document.page_image_url}
                    width={100}
                    alt="preview-document"
                  />
                ) : (
                  <Article color="action" />
                )}
              </PreviewContainer>
              <InfoContainer>
                <AccountCircle color="action" />
                <p>Criado por</p>
                {document.upload_user?.name.split(" ")[0] || document.user_superlogica}
                <Circle style={{ width: "5px" }} color="action" />
                {formatDate(document.updatedAt)}
              </InfoContainer>
            </DocumentCardContainer>
            <EditDocumentModal
              isVisible={isModalOpen}
              onClose={handleCloseModal}
              documentTypes={types}
              documentTopics={topics}
              onSubmit={handleSaveDocument}
              condominium={document?.condo}
              user={document?.upload_user}
              document={document}
            />
          </>
        )}
      </>
    );
  }
);

export default DocumentCard;
