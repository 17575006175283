import styled from "styled-components";

export const MainContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    overflow: hidden;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  width: 350px;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 0;
    width: 250px;
  }
`;
