import React, { createContext, useState, useContext } from 'react';

const CondoContext = createContext();

export const useCondo = () => useContext(CondoContext);

export const CondoProvider = ({ children }) => {
    const [selectedCondo, setSelectedCondo] = useState(null);

    const handleCondoChange = (condo) => {
        setSelectedCondo(condo);
    };

    return (
        <CondoContext.Provider value={{ selectedCondo, handleCondoChange }}>
            {children}
        </CondoContext.Provider>
    );
};
