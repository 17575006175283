import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  Col,
  Row,
  notification,
  Progress,
} from "antd";
import { useApi } from "../../hooks/useApi";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { UploadProgressContext } from "../../context/uploadProgressContext";
import { useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const EditDocumentModal = ({
  isVisible,
  onClose,
  onSubmit,
  user,
  condominium,
  document,
  documentTypes,
  documentTopics,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const { uploadProgress } = useContext(UploadProgressContext);

  //DOCUMENT PREVIEW
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const zoomIn = () => {
    setScale(scale + 0.1);
  };

  const zoomOut = () => {
    if (scale > 0.2) setScale(scale - 0.2);
  };

  const handleClose = () => {
    form.setFieldsValue({
      title: document.title,
      ref_date: document.ref_date ? dayjs(new Date(document.ref_date)) : null,
      doc_type: document.doc_type,
      doc_topics: document.doc_topics,
      people_involved: document.people_involved,
      companies_involved: document.companies_involved,
      tags: document.tags,
    });
    onClose();
  };

  const containerStyle = {
    width: "650px",
    height: "500px",
    overflow: "auto",
    marginBottom: "20px",
  };
  useEffect(() => {
    if (document) {
      form.setFieldsValue({
        title: document.title,
        ref_date: document.ref_date ? dayjs(new Date(document.ref_date)) : null,
        doc_type: document.doc_type,
        doc_topics: document.doc_topics,
        people_involved: document.people_involved,
        companies_involved: document.companies_involved,
        tags: document.tags,
      });
    }
  }, [document, form]);

  useEffect(() => {
    form.setFieldsValue({
      condo: condominium?.name,
      upload_user: user?.name || document?.user_superlogica,
    });
  }, [form, condominium, user]);

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      if (values.ref_date) {
        values.ref_date = values.ref_date.toISOString();
      }
      values.condo = condominium._id;
      values.upload_user = document.is_superlogica_doc ? null : user._id;

      await onSubmit(values);
      notification.success({
        message: "Documento atualizado com sucesso!",
      });
    } catch (error) {
      notification.error({
        message: "Erro ao adicionar documento!",
        description: `${error}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Editar Documento"
      open={isVisible}
      onCancel={handleClose}
      footer={null}
      width={1100}
      style={{
        marginRight: !isMobile && "80px",
        marginTop: isMobile && "40px",
      }}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={16}>
          <Col span={isMobile ? 28 : 8}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="title" label="Título">
                  <Input value={document?.title} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="ref_date" label="Data de Referência">
                  <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="upload_user" label="Usuário">
                  <Input value={user} disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="condo"
                  label="Condomínio"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecione um condomínio na Home.",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item name="doc_type" label="Tipo de Documento">
                  <Select>
                    {documentTypes &&
                      documentTypes.map((type) => (
                        <Select.Option key={type._id} value={type.name}>
                          {type.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="doc_topics" label="Assunto">
                  <Select>
                    {documentTopics &&
                      documentTopics.map((topic) => (
                        <Select.Option key={topic._id} value={topic.name}>
                          {topic.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="people_involved" label="Pessoas Envolvidas">
                  <Select mode="tags" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="companies_involved"
                  label="Empresas Envolvidas"
                >
                  <Select mode="tags" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="tags" label="Etiquetas">
                  <Select mode="tags" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {!isMobile && (
            <Col
              span={16}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {document.document ? (
                <div>
                  <div style={containerStyle}>
                    <Document
                      file={document.document}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <div
                        style={{
                          transform: `scale(${scale})`,
                          transformOrigin: "0 0",
                        }}
                      >
                        <Page pageNumber={pageNumber} width={650} />
                      </div>
                    </Document>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      onClick={previousPage}
                      disabled={pageNumber <= 1}
                      style={{ marginRight: "10px" }}
                    >
                      {"<"}
                    </Button>
                    <span>
                      Page {pageNumber} of {numPages}
                    </span>
                    <Button
                      onClick={nextPage}
                      disabled={pageNumber >= numPages}
                      style={{ marginLeft: "10px" }}
                    >
                      {">"}
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px 0",
                    }}
                  >
                    <Button
                      onClick={zoomOut}
                      disabled={scale <= 0.2}
                      style={{ marginRight: "10px" }}
                    >
                      -
                    </Button>
                    <Button onClick={zoomIn} disabled={scale >= 2}>
                      +
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "500px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>Preview do Documento</span>
                </div>
              )}
            </Col>
          )}
        </Row>
        {uploadProgress > 0 && (
          <Progress percent={uploadProgress} status="active" />
        )}
        <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ backgroundColor: "#1b7884" }}
          >
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditDocumentModal;
