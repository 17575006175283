export const checkOCRText = (text) => {
  const DocTopicsCategories = {
    Financeiro: ["conta", "boleto", "fatura", "financeiro", "rescisao"],
    RH: [
      "holerite",
      "demissão",
      "admissão",
      "recursos humanos",
      "contratação",
      "férias",
      "atestado",
    ],
    Relacionamento: ["relacionamento", "contato", "telefone", "mensagem"],
  };

  const DocTypeCategories = {
    Boleto: ["boleto", "fatura", "valor da conta"],
    NFE: ["nota fiscal", "prestação de serviço", "prefeitura", "dados da nf-e"],
    Holerite: ["holerite", "recibo de pagamento"],
    Declaração: ["declaração", "depoimento"],
    Mensagem: ["mensagem", "contato", "email"],
    Ata: ["reunião", "agenda", "atividade", "eventos"],
    Contrato: ["contrato", "admissão"],
    Convenção: ["convenção", "especificação"],
    Prestação: ["prestação de contas"],
  };

  const categoryNames = {
    RH: "Recursos Humanos",
    NFE: "Nota Fiscal",
    Prestação: "Prestação de Contas",
    Convenção: "Convenção e Especificação",
  };

  let topicResult = null;
  let typeResult = null;

  const lowerText = text.toLowerCase();

  for (const [category, keyWords] of Object.entries(DocTopicsCategories)) {
    const hasWords = keyWords.some((word) => lowerText.includes(word));
    if (hasWords) {
      topicResult = categoryNames[category] || category;
      break;
    }
  }

  for (const [category, keyWords] of Object.entries(DocTypeCategories)) {
    const hasWords = keyWords.some((word) => lowerText.includes(word));
    if (hasWords) {
      typeResult = categoryNames[category] || category;
      break;
    }
  }

  return {
    topic: topicResult || "Outro",
    type: typeResult || "Outro",
  };
};
