import React, { createContext, useContext, useState } from "react";

const FilterContext = createContext();

export const useFilters = () => useContext(FilterContext);

export const FilterProvider = ({ children }) => {
  const [dateRangeRefDate, setDateRangeRefDate] = useState([]);
  const [dateRangeUpload, setDateRangeUpload] = useState([]);

  const handleDateChangeRefDate = (dateStrings) => {
    setDateRangeRefDate(dateStrings);
  };

  const handleDateChangeUpload = (dateStrings) => {
    setDateRangeUpload(dateStrings);
  };

  return (
    <FilterContext.Provider
      value={{
        dateRangeRefDate,
        setDateRangeRefDate,
        dateRangeUpload,
        setDateRangeUpload,
        handleDateChangeRefDate,
        handleDateChangeUpload,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
