import React, { createContext, useState, useContext, useCallback } from "react";
import { useCondo } from "./CondoContext";

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchHandler, setSearchHandler] = useState(() => () => { });
  const [selectedCondoState, setSelectedCondo] = useState({ id: "", name: "" });
  const { selectedCondo } = useCondo()

  const clearSearch = () => {
    setSearchQuery("");
    searchHandler(1, "", selectedCondo?.id);
  };

  const registerSearchHandler = useCallback((handler) => {
    setSearchHandler(
      () =>
        (...args) =>
          handler(...args)
    );
  }, []);

  return (
    <SearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        clearSearch,
        selectedCondo,
        setSelectedCondo,
        registerSearchHandler,
        searchHandler,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
