import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { IoCloseCircleOutline, IoSearch } from "react-icons/io5";
import { useSearch } from "../../context/SearchContext";
import {
  ContentNavBar,
  FiltersContainer,
  UserContainer,
} from "./styled";
import bluelogo from "../../assets/logos/bluelogo.png";
import { Tune } from "@mui/icons-material";
import { Popover } from "antd";
import { DatePicker } from "antd";
import { useFilters } from "../../context/FiltersContext";
import { useApi } from "../../hooks/useApi";
import { useCondo } from "../../context/CondoContext";
const { RangePicker } = DatePicker;

const Navbar = ({ handleDrawerToggle }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userCondominiums, setUserCondominiums] = useState([]);
  const [loading, setLoading] = useState(true);

  const { selectedCondo, handleCondoChange } = useCondo();


  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);
  const { handleDateChangeRefDate, handleDateChangeUpload } = useFilters();
  const { data: condominiums } = useApi(
    `${process.env.REACT_APP_API_MAIN}/condominiums`
  );


  const {
    searchQuery,
    setSearchQuery,
    clearSearch,
    setSelectedCondo,
    searchHandler,
  } = useSearch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
    navigate("/");
  };

  useEffect(() => {
    if (user && user.condoRoles) {
      const condos = user.condoRoles.map((condoRole) => ({
        _id: condoRole.condo?._id,
        name: condoRole.condo?.name,
      })).filter((condo) => condo._id !== undefined);
      setUserCondominiums(condos);
      if (condos.length > 0 && !selectedCondo) {
        setSelectedCondo({
          id: condos[0]._id,
          name: condos[0].name,
        });
        handleCondoChange({
          id: condos[0]._id,
          name: condos[0].name,
        });
      }
    }
    setLoading(false);
  }, [user]);


  const onCondoChange = (event) => {
    const selectedId = event.target.value;
    const selectedCondo = user.isMaster
      ? condominiums.find((condo) => condo._id === selectedId)
      : userCondominiums.find((condo) => condo._id === selectedId);

    if (selectedCondo) {
      setSelectedCondo({
        id: selectedCondo._id,
        name: selectedCondo.name,
      });
    }
    handleCondoChange({
      id: selectedCondo._id,
      name: selectedCondo.name
    });
  };


  const filterContent = (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
        }}
      >
        <Typography>Data de referência</Typography>
        <RangePicker
          placeholder={["Data Inicial", "Data Final"]}
          onChange={handleDateChangeRefDate}
          format="DD/MM/YYYY"
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography>Data de criação</Typography>
        <RangePicker
          placeholder={["Data Inicial", "Data Final"]}
          onChange={handleDateChangeUpload}
          format="DD/MM/YYYY"
        />
      </div>
    </div>
  );
  return (
    <>
      <AppBar position="fixed" style={{ background: "#1B6684" }}>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Toolbar
            style={{
              justifyContent: "space-around",
              width: isMobile ? "100%" : "80%",
              marginLeft: isMobile ? "0" : "auto",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { xs: "block", sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <ContentNavBar>
              <FiltersContainer>
                {!loading && (
                  <Select
                    value={selectedCondo?.id || ""}
                    variant="standard"
                    onChange={onCondoChange}
                    label="Condomínio"
                    style={{
                      width: isMobile ? "180px" : "400px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      color: "white",
                    }}
                  >
                    {(user?.isMaster ? condominiums : userCondominiums).map(
                      (condo) => (
                        <MenuItem
                          key={condo._id}
                          value={condo._id}
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {condo.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
                <div></div>
                <TextField
                  placeholder="Pesquisar"
                  variant="outlined"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      searchHandler(1, searchQuery, selectedCondo?.id);
                    }
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "20px",
                      width: isMobile ? "200px" : "500px",
                      height: "45px",
                      backgroundColor: "#E5F5FD",
                    },
                    startAdornment: searchQuery ? (
                      <InputAdornment position="end">
                        <IconButton onClick={clearSearch}>
                          <IoCloseCircleOutline />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton>
                          <IoSearch />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Popover
                          content={filterContent}
                          title="Filtros por data"
                          trigger="click"
                          placement="bottom"
                        >
                          <IconButton>
                            <Tune />
                          </IconButton>
                        </Popover>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      lineHeight: "15px",
                    },
                  }}
                />
              </FiltersContainer>
              <UserContainer>
                {!isMobile && (
                  <Typography>{user?.name.split(" ")[0]}</Typography>
                )}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/profile");
                      handleClose();
                    }}
                  >
                    Editar
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Sair</MenuItem>
                </Menu>
              </UserContainer>
            </ContentNavBar>
          </Toolbar>
        </div>
      </AppBar>
    </>
  );
};

export default Navbar;
