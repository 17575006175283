import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  Upload,
  Col,
  Row,
  notification,
  Progress,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useApi } from "../../hooks/useApi";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { UploadProgressContext } from "../../context/uploadProgressContext";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDocumentFieldOptions } from "../../hooks/useDocumentFieldOptions";
import { textractOCR } from "../../utils/TextractOCR";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const { Dragger } = Upload;

const NewDocumentModal = ({
  isVisible,
  onClose,
  onSubmit,
  user,
  condominium,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loadingFields, setLoadingFields] = useState({
    docType: false,
    docTopics: false,
  });

  const [documentQueue, setDocumentQueue] = useState([]);
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { uploadProgress } = useContext(UploadProgressContext);
  const token = localStorage.getItem("token");

  const twoColors = {
    "0%": "#108ee9",
    "100%": "#87d068",
  };

  const peopleOptions = useDocumentFieldOptions(
    `${process.env.REACT_APP_API_MAIN}/documents`,
    "people_involved",
    token
  );
  const companiesOptions = useDocumentFieldOptions(
    `${process.env.REACT_APP_API_MAIN}/documents`,
    "companies_involved",
    token
  );
  const tagsOptions = useDocumentFieldOptions(
    `${process.env.REACT_APP_API_MAIN}/documents`,
    "tags",
    token
  );

  //DOCUMENT PREVIEW
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const zoomIn = () => {
    setScale(scale + 0.1);
  };

  const zoomOut = () => {
    if (scale > 0.2) setScale(scale - 0.2);
  };

  const containerStyle = {
    width: "650px",
    height: "500px",
    overflow: "auto",
    marginBottom: "20px",
  };

  const { data: documentTypesData } = useApi(
    `${process.env.REACT_APP_API_MAIN}/doc-types`
  );
  const { data: documentTopics } = useApi(
    `${process.env.REACT_APP_API_MAIN}/doc-topics` //SORT POR ORDEM ALFABETICA
  );


  const documentTypes = useMemo(() => {
    return documentTypesData
      ? [...documentTypesData].sort((a, b) => a.name.localeCompare(b.name))
      : [];
  }, [documentTypesData]);

  useEffect(() => {
    form.setFieldsValue({
      condo: condominium?.name,
      upload_user: user?.name,
    });
  }, [form, condominium, user]);

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      if (values.ref_date) {
        values.ref_date = values.ref_date.toISOString();
      }
      const currentDocument = fileList[currentDocumentIndex];
      if (currentDocument) {
        values.document = currentDocument.originFileObj;
      }

      values.condo = condominium.id;
      values.upload_user = user._id;

      const onSuccess = () => {
        let newQueue = [...documentQueue];
        newQueue.splice(currentDocumentIndex, 1);

        let newFileList = [...fileList];
        newFileList.splice(currentDocumentIndex, 1);

        setDocumentQueue(newQueue);
        setFileList(newFileList);

        if (newQueue.length > 0) {
          setCurrentDocumentIndex(
            currentDocumentIndex >= newQueue.length ? 0 : currentDocumentIndex
          );
          setSelectedFile(
            newQueue[
              currentDocumentIndex >= newQueue.length ? 0 : currentDocumentIndex
            ]?.file
          );
        } else {
          setCurrentDocumentIndex(0);
          setSelectedFile(null);
          onClose();
        }

        const currentValues = form.getFieldsValue(["upload_user", "condo"]);
        form.resetFields();
        form.setFieldsValue({ document: newFileList });
        form.setFieldsValue(currentValues);
      };

      const onError = () => {
        notification.error({
          message: "Erro ao adicionar documento!",
          description:
            "Não foi possível enviar o documento. Por favor, tente novamente.",
        });
      };

      await onSubmit(values, onSuccess, onError, numPages);
    } catch (error) {
      notification.error({
        message: "Erro ao preparar o documento para envio!",
        description: error.toString(),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFileChange = async ({ fileList: newFileList }) => {
    const newQueue = newFileList.map((file) => ({
      file: URL.createObjectURL(file.originFileObj),
      originFileObj: file.originFileObj,
    }));
    setDocumentQueue(newQueue);
    setFileList(newFileList);

    if (newQueue.length > 0) {
      setSelectedFile(newQueue[0].file);
    } else {
      setSelectedFile(null);
    }

    form.setFieldsValue({ document: newFileList });
  };

  const onDrop = async (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type === "application/pdf") {
        setLoadingFields({ docType: true, docTopics: true });
        const response = await textractOCR(file);
        form.setFieldsValue({
          doc_type: response?.type,
          doc_topics: response?.topic,
        });
        setLoadingFields({ docType: false, docTopics: false });
      } else {
        notification.error({
          message: "Tipo de arquivo não suportado!",
          description: "Apenas arquivos PDF são permitidos.",
        });
      }
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onSelectDocumentOCR = async () => {
    setLoadingFields({ docType: true, docTopics: true });
    if (documentQueue.length === 1) {
      const response = await textractOCR(documentQueue[0].originFileObj);
      form.setFieldsValue({
        doc_type: response?.type,
        doc_topics: response?.topic,
      });
      setLoadingFields({ docType: false, docTopics: false });
    } else if (
      documentQueue.length > 1 &&
      documentQueue[currentDocumentIndex]?.originFileObj
    ) {
      const response = await textractOCR(
        documentQueue[currentDocumentIndex].originFileObj
      );
      form.setFieldsValue({
        doc_type: response?.type,
        doc_topics: response?.topic,
      });
      setLoadingFields({ docType: false, docTopics: false });
    } else {
      form.setFieldsValue({
        doc_type: "",
        doc_topics: "",
      });
      setLoadingFields({ docType: false, docTopics: false });
    }
  };

  useEffect(() => {
    const changeDocumentsInQueue = async () => {
      if (documentQueue[currentDocumentIndex]) {
        setSelectedFile(documentQueue[currentDocumentIndex].file);
      }

      await onSelectDocumentOCR();
    };
    changeDocumentsInQueue();
  }, [currentDocumentIndex, documentQueue]);

  const goToPreviousDocument = () => {
    form.setFieldsValue({
      doc_type: "",
      doc_topics: "",
    });
    setCurrentDocumentIndex((prevIndex) => {
      let newIndex = prevIndex - 1;
      if (newIndex < 0) {
        newIndex = documentQueue.length - 1;
      }
      setSelectedFile(documentQueue[newIndex].file);
      return newIndex;
    });
  };

  const goToNextDocument = () => {
    form.setFieldsValue({
      doc_type: "",
      doc_topics: "",
    });
    setCurrentDocumentIndex((prevIndex) => {
      let newIndex = (prevIndex + 1) % documentQueue.length;
      setSelectedFile(documentQueue[newIndex].file);
      return newIndex;
    });
  };

  return (
    <Modal
      title="Novo Documento"
      open={isVisible}
      onCancel={() => {
        const uploadUserValue = form.getFieldValue("upload_user");
        const condoValue = form.getFieldValue("condo");

        onClose();
        form.resetFields();

        form.setFieldsValue({
          upload_user: uploadUserValue,
          condo: condoValue,
        });

        setDocumentQueue([]);
        setSelectedFile(null);
      }}
      footer={null}
      width={1100}
      style={{
        marginRight: !isMobile && "80px",
        marginTop: isMobile && "40px",
      }}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={16}>
          <Col span={isMobile ? 28 : 8}>
            <Row gutter={16}>
              {documentQueue.length > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    marginBottom: "10px",
                    gap: "20px",
                  }}
                >
                  <Button onClick={goToPreviousDocument}>
                    Documento Anterior
                  </Button>
                  <Button onClick={goToNextDocument}>Próximo Documento</Button>
                </div>
              )}
              <Col span={24}>
                <Form.Item
                  name="document"
                  label="Documento (PDF)"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: "Por favor, faça o upload do documento!",
                    },
                  ]}
                >
                  <Dragger
                    name="document"
                    accept=".pdf"
                    maxCount={5}
                    beforeUpload={() => false}
                    onChange={onFileChange}
                    onDrop={onDrop}
                    fileList={fileList}
                  >
                    <p
                      className="ant-upload-drag-icon"
                      style={{ height: "20px", marginTop: "-10px" }}
                    >
                      <UploadOutlined style={{ width: "30px" }} />
                    </p>
                    <p className="ant-upload-text" style={{ height: "15px" }}>
                      Clique ou arraste um arquivo (.pdf)
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="title" label="Título">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="ref_date" label="Data de Referência">
                  <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="upload_user" label="Usuário">
                  <Input value={user} disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="condo"
                  label="Condomínio"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecione um condomínio na Home.",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item name="doc_type" label="Tipo de Documento">
                  <Select loading={loadingFields.docType}>
                    {documentTypes &&
                      documentTypes.map((type) => (
                        <Select.Option key={type._id} value={type.name}>
                          {type.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="doc_topics" label="Assunto">
                  <Select loading={loadingFields.docTopics}>
                    {documentTopics &&
                      documentTopics.map((topic) => (
                        <Select.Option key={topic._id} value={topic.name}>
                          {topic.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="people_involved" label="Pessoas Envolvidas">
                  <Select
                    mode="tags"
                    options={peopleOptions.map((person) => ({
                      label: person,
                      value: person,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="companies_involved"
                  label="Empresas Envolvidas"
                >
                  <Select
                    mode="tags"
                    options={companiesOptions.map((company) => ({
                      label: company,
                      value: company,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="tags" label="Etiquetas">
                  <Select
                    mode="tags"
                    options={tagsOptions.map((tag) => ({
                      label: tag,
                      value: tag,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {!isMobile && (
            <Col
              span={16}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onDrop={onDrop}
                onDragOver={onDragOver}
              >
                {selectedFile ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "100px",
                    }}
                  >
                    <div style={containerStyle}>
                      <Document
                        file={selectedFile}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <div
                          style={{
                            transform: `scale(${scale})`,
                            transformOrigin: "0 0",
                          }}
                        >
                          <Page pageNumber={pageNumber} width={650} />
                        </div>
                      </Document>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={previousPage}
                        disabled={pageNumber <= 1}
                        style={{ marginRight: "10px" }}
                      >
                        {"<"}
                      </Button>
                      <span>
                        Page {pageNumber} of {numPages}
                      </span>
                      <Button
                        onClick={nextPage}
                        disabled={pageNumber >= numPages}
                        style={{ marginLeft: "10px" }}
                      >
                        {">"}
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "10px 0",
                      }}
                    >
                      <Button
                        onClick={zoomOut}
                        disabled={scale <= 0.2}
                        style={{ marginRight: "10px" }}
                      >
                        -
                      </Button>
                      <Button onClick={zoomIn} disabled={scale >= 2}>
                        +
                      </Button>
                    </div>
                  </div>
                ) : (
                  <span>Arraste um documento PDF aqui para pré-visualizar</span>
                )}
              </div>
            </Col>
          )}
        </Row>
        {uploadProgress > 0 && (
          <Progress percent={uploadProgress} strokeColor={twoColors} />
        )}
        <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ backgroundColor: "#1b7884" }}
          >
            Adicionar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewDocumentModal;
