import React, { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Alert,
  Box,
  IconButton,
  Pagination,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DocumentsContainer, FilterButtonsContainer } from "./styled";
import DocumentCard from "../../components/DocumentCard/DocumentCard";
import { Radio, Select, notification } from "antd";
import { useApi } from "../../hooks/useApi";
import { useSearch } from "../../context/SearchContext";
import { useDrawerContext } from "../../context/DrawerContext";
import useSelectOptions from "../../hooks/useSelectOptions";
import { useFilters } from "../../context/FiltersContext";
import { Close, Delete } from "@mui/icons-material";
import ConfirmationModal from "../../components/DeleteConfirmModal/DeleteConfirmModal";
import { AuthContext } from "../../context/AuthContext";
import { useDocuments } from "../../context/DocumentsContext";

const Home = () => {
  const [documents, setDocuments] = useState([]);
  const [unindexedDocuments, setUnindexedDocument] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  //FILTERS
  const [docType, setDocType] = useState("");
  const [docTopics, setDocTopics] = useState("");
  const [peopleInvolved, setPeopleInvolved] = useState("");
  const [companiesInvolved, setCompaniesInvolved] = useState("");
  const [tags, setTags] = useState("");
  const [isUnindexedSelected, setUnindexedSelected] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [alertOpen, setAlertOpen] = useState(true);

  const { searchQuery, selectedCondo, registerSearchHandler } = useSearch();
  const { drawerExpanded } = useDrawerContext();
  const { triggerUpdate } = useDocuments();
  const { dateRangeRefDate, dateRangeUpload } = useFilters();

  const drawerWidth = 200;
  const miniDrawerWidth = 100;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const drawerMargin = drawerExpanded ? drawerWidth : miniDrawerWidth;

  const token = localStorage.getItem("token");
  const { user } = useContext(AuthContext);

  const { data: documentTypes } = useApi(
    `${process.env.REACT_APP_API_MAIN}/doc-types`
  );

  const documentTypeOptions = documentTypes.map((type) => ({
    label: type.name,
    value: type.name,
  })).sort((a, b) => a.label.localeCompare(b.label));

  const { data: documentTopics } = useApi(
    `${process.env.REACT_APP_API_MAIN}/doc-topics`
  );

  const documentTopicOptions = documentTopics.map((type) => ({
    label: type.name,
    value: type.name,
  }));

  const fetchDocumentsWithFilter = useCallback(
    async (page = 1, search = "", condoId = selectedCondo?.id) => {
      setLoading(true);
      try {
        let params = {
          page: page,
          limit: 15,
          search: search,
          doc_type: docType,
          doc_topics: docTopics,
          people_involved: peopleInvolved,
          companies_involved: companiesInvolved,
          tags: tags,
          isUnindexed: isUnindexedSelected,
        };

        if (dateRangeRefDate && dateRangeRefDate.length === 2) {
          const [start, end] = dateRangeRefDate;
          params.refDateStart = start.format("YYYY-MM-DD");
          params.refDateEnd = end.format("YYYY-MM-DD");
        }

        if (dateRangeUpload && dateRangeUpload.length === 2) {
          const [start, end] = dateRangeUpload;
          params.createdAtStart = start.format("YYYY-MM-DD");
          params.createdAtEnd = end.format("YYYY-MM-DD");
        }

        if (!condoId) {
          return;
        }

        const endpoint = `${process.env.REACT_APP_API_MAIN}/documents/${condoId}`;

        const response = await axios.get(endpoint, {
          headers: {
            Authorization: token,
          },
          params: params,
        });
        setUnindexedDocument(response.data.unindexed);
        setDocuments(response.data.documents);
        setTotalPages(response.data.totalPages);
        setAlertOpen(true);
      } catch (error) {
        notification.error({
          message: "Erro ao buscar documentos!",
          description: `${error}`,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      selectedCondo?.id,
      token,
      dateRangeRefDate,
      dateRangeUpload,
      docType,
      docTopics,
      peopleInvolved,
      companiesInvolved,
      tags,
      isUnindexedSelected,
    ]
  );

  useEffect(() => {
    fetchDocumentsWithFilter(currentPage, searchQuery);
  }, [currentPage, fetchDocumentsWithFilter, triggerUpdate]);

  useEffect(() => {
    registerSearchHandler(fetchDocumentsWithFilter);
  }, [registerSearchHandler, fetchDocumentsWithFilter]);

  const updateDocumentInList = (updatedDocument) => {
    setDocuments(
      documents.map((doc) =>
        doc._id === updatedDocument._id ? updatedDocument : doc
      )
    );
  };

  const renderSkeletons = () => {
    return Array.from({ length: 15 }).map((_, index) => (
      <DocumentCard key={index} loading={true} />
    ));
  };

  const handleDeleteSelectedDocuments = async () => {
    if (selectedDocumentIds.length === 0) {
      notification.info({
        message: "Nenhum documento selecionado",
        description: "Por favor, selecione ao menos um documento para deletar.",
      });
      return;
    }

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_MAIN}/documents/delete-multiple`,
        {
          data: { documentIds: selectedDocumentIds, user: user._id },
          headers: {
            Authorization: token,
          },
        }
      );

      setDocuments(
        documents.filter((doc) => !selectedDocumentIds?.includes(doc._id))
      );
      setSelectedDocumentIds([]);
      notification.success({
        message: "Documentos deletados com sucesso!",
        description: response.data.message,
      });
    } catch (error) {
      notification.error({
        message: "Erro ao deletar documentos",
        description: error.message,
      });
    }
  };

  const peopleOptions = useSelectOptions(documents, "people_involved");
  const companiesOptions = useSelectOptions(documents, "companies_involved");
  const tagsOptions = useSelectOptions(documents, "tags");

  return (
    <div
      style={{
        display: "flex",
        marginTop: "6rem",
        width: isMobile ? "100%" : `calc(100% - ${drawerMargin}px)`,
        overflowX: "hidden",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: !isMobile && drawerMargin,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: "90%",
          marginTop: isMobile && "30px",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h5">Documentos</Typography>
      </div>
      {alertOpen && (
        <Alert
          severity="info"
          style={{ width: "90%", display: unindexedDocuments <= 0 && "none" }}
          onClose={() => setAlertOpen(false)}
        >
          Existem <strong>{unindexedDocuments}</strong> documentos sem indexação
          neste condomínio. Para adicioná-los clique no documento para editar.
        </Alert>
      )}

      {selectedDocumentIds.length > 0 ? (
        <Alert
          severity="warning"
          style={{ width: "90%", borderRadius: "90px", marginTop: "10px" }}
          onClose={() => setSelectedDocumentIds([])}
          icon={false}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setSelectedDocumentIds([])}
            >
              <Close style={{ width: "20px" }} />
            </IconButton>
          }
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex" alignItems="center" marginLeft="10px">
              <span>
                <strong>{selectedDocumentIds?.length}</strong> documentos
                selecionados
              </span>
            </Box>
            <IconButton
              aria-label="delete"
              size="small"
              style={{ marginLeft: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenConfirmationModal(true);
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        </Alert>
      ) : (
        <FilterButtonsContainer>
          <Radio.Group
            value={isUnindexedSelected}
            onChange={(e) => !setUnindexedSelected(e.target.value)}
          >
            <Radio.Button value={false}>Indexados</Radio.Button>
            <Radio.Button value={true}>Não indexados</Radio.Button>
          </Radio.Group>
          {!isMobile && (
            <div
              style={{
                height: "30px",
                width: "1px",
                backgroundColor: "#E0E0E0",
              }}
            />
          )}
          <Select
            placeholder="Tipo"
            allowClear
            style={{ width: isMobile ? 160 : 120 }}
            onChange={(value) => setDocType(value)}
            options={documentTypeOptions}
          />
          <Select
            placeholder="Assunto"
            allowClear
            style={{ width: isMobile ? 160 : 120 }}
            onChange={(value) => setDocTopics(value)}
            options={documentTopicOptions}
          />
          <Select
            mode="tags"
            tokenSeparators={[","]}
            style={{ width: 160 }}
            allowClear
            placeholder="Pessoas Envolvidas"
            onChange={(value) => setPeopleInvolved(value)}
            options={peopleOptions}
          />
          <Select
            mode="tags"
            tokenSeparators={[","]}
            style={{ width: 160 }}
            allowClear
            placeholder="Empresas envolvidas"
            onChange={(value) => setCompaniesInvolved(value)}
            options={companiesOptions}
          />
          <Select
            mode="tags"
            tokenSeparators={[","]}
            style={{ width: 160 }}
            allowClear
            placeholder="Etiquetas"
            onChange={(value) => setTags(value)}
            options={tagsOptions}
          />
        </FilterButtonsContainer>
      )}
      <DocumentsContainer>
        {loading && documents.length === 0
          ? renderSkeletons()
          : documents.map((document) => (
              <DocumentCard
                key={document._id}
                document={document}
                types={documentTypes}
                topics={documentTopics}
                loading={loading}
                onUpdate={updateDocumentInList}
                selectedDocumentIds={selectedDocumentIds}
                setSelectedDocumentIds={setSelectedDocumentIds}
              />
            ))}
      </DocumentsContainer>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => setCurrentPage(page)}
        style={{ marginBottom: "3rem", marginTop: "2rem" }}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        onConfirm={() => {
          handleDeleteSelectedDocuments();
          setOpenConfirmationModal(false);
        }}
        dialogText={` Tem certeza de que deseja deletar ${selectedDocumentIds.length} documentos
        selecionados?`}
      />
    </div>
  );
};

export default React.memo(Home);
