import {
  Alert,
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CondominiumCard from "../../components/CondominiumCard/CondominiumCard";
import { ButtonContainer, CondominiumContainer } from "./styled";
import { useEffect, useState } from "react";
import { useDrawerContext } from "../../context/DrawerContext";
import { notification } from "antd";
import axios from "axios";
import ConfirmationModal from "../../components/DeleteConfirmModal/DeleteConfirmModal";
import { Add, Close, Delete } from "@mui/icons-material";
import CondominiumModal from "../../components/CondominiumModal/CondominiumModal";

export const Condominiums = () => {
  const drawerWidth = 200;
  const miniDrawerWidth = 100;
  const { drawerExpanded } = useDrawerContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const drawerMargin = drawerExpanded ? drawerWidth : miniDrawerWidth;

  const [condominiums, setCondominiums] = useState([]);
  const [alertOpen, setAlertOpen] = useState(true);
  const [selectedCondominiumsIds, setSelectedCondominiumsIds] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchCondominiums = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_MAIN}/condominiums`
        );
        setCondominiums(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCondominiums();
  }, []);

  const updateCondominiumInState = (updatedCondo) => {
    const updatedCondominiums = condominiums.map((condo) =>
      condo._id === updatedCondo._id ? updatedCondo : condo
    );
    setCondominiums(updatedCondominiums);
  };

  const handleDeleteSelectedCondominiums = async () => {
    if (selectedCondominiumsIds.length === 0) {
      notification.info({
        message: "Nenhum condomínio selecionado",
        description:
          "Por favor, selecione ao menos um condomínio para deletar.",
      });
      return;
    }

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_MAIN}/condominiums/delete-multiple`,
        {
          data: { condominiumIds: selectedCondominiumsIds },
        }
      );

      setSelectedCondominiumsIds([]);
      setCondominiums(
        condominiums.filter((condo) => !selectedCondominiumsIds?.includes(condo._id))
      );
      notification.success({
        message: "Condomínios deletados com sucesso!",
        description: response.data.message,
      });
    } catch (error) {
      notification.error({
        message: "Erro ao deletar condomínios",
        description: error.message,
      });
    }
  };

  const handleAddCondominium = async (newCondominiumData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_MAIN}/condominiums`,
        newCondominiumData
      );
      setCondominiums([...condominiums, response.data]);
      notification.success({
        message: "Condomínio adicionado com sucesso!",
      });
    } catch (error) {
      console.error("Erro ao adicionar condomínio:", error);
      notification.error({
        message: "Erro ao adicionar condomínio",
        description: error.message,
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        marginTop: "6rem",
        width: isMobile ? "100%" : `calc(100% - ${drawerMargin}px)`,
        overflowX: "hidden",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: !isMobile && drawerMargin,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: "90%",
          marginTop: isMobile && "30px",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h5">Condomínios</Typography>
      </div>
      {alertOpen && (
        <Alert
          severity="info"
          style={{ width: "90%" }}
          onClose={() => setAlertOpen(false)}
        >
          Como usuário master, você tem direito a editar, excluir ou adicionar
          um novo condomínio.
        </Alert>
      )}
      {selectedCondominiumsIds.length > 0 ? (
        <Alert
          severity="warning"
          style={{ width: "90%", borderRadius: "90px", marginTop: "10px" }}
          onClose={() => setSelectedCondominiumsIds([])}
          icon={false}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setSelectedCondominiumsIds([])}
            >
              <Close style={{ width: "20px" }} />
            </IconButton>
          }
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex" alignItems="center" marginLeft="10px">
              <span>
                <strong>{selectedCondominiumsIds?.length}</strong> condomínios
                selecionados
              </span>
            </Box>
            <IconButton
              aria-label="delete"
              size="small"
              style={{ marginLeft: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenConfirmationModal(true);
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        </Alert>
      ) : (
        <ButtonContainer>
          <Button
            startIcon={<Add />}
            variant="contained"
            style={{
              background: "#1b7884",
              height: "50px",
              marginLeft: "10px",
              marginTop: "10px",
              marginBottom: "10px",
              color: "#FFFBF0",
            }}
            onClick={() => setOpenModal(true)}
          >
            Adicionar
          </Button>
        </ButtonContainer>
      )}
      <CondominiumContainer>
        {condominiums.map((condo) => (
          <CondominiumCard
            key={condo._id}
            condominium={condo}
            selectedCondominiumsIds={selectedCondominiumsIds}
            setSelectedCondominiumsIds={setSelectedCondominiumsIds}
            onUpdate={updateCondominiumInState}
          />
        ))}
      </CondominiumContainer>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        onConfirm={() => {
          handleDeleteSelectedCondominiums();
          setOpenConfirmationModal(false);
        }}
        dialogText={` Tem certeza de que deseja deletar ${selectedCondominiumsIds.length} condomínios
        selecionados?`}
      />
      <CondominiumModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleAddCondominium}
      />
    </div>
  );
};

export default Condominiums;
