import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedUser = JSON.parse(localStorage.getItem("user"));

    if (storedUser) setUser(storedUser);
    if (storedEmail) setEmail(storedEmail);
  }, []);

  useEffect(() => {
    localStorage.setItem("email", email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const logout = () => {
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  return (
    <AuthContext.Provider
      value={{ email, setEmail, logout, user, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
