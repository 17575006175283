import React, { createContext, useState } from 'react';

export const UploadProgressContext = createContext();

export const UploadProgressProvider = ({ children }) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  return (
    <UploadProgressContext.Provider value={{ uploadProgress, setUploadProgress }}>
      {children}
    </UploadProgressContext.Provider>
  );
};