import React, { useEffect, useState } from "react";
import { Modal, Form, Input } from "antd";
import formatCNPJ from "../../utils/formatCNPJ";

function CondominiumModal({ open, onClose, onSubmit, initialValues }) {
  const [form] = Form.useForm();
  const [cnpjValue, setCnpjValue] = useState("");

  useEffect(() => {
    if (initialValues && initialValues.cnpj) {
      const formattedCnpj = formatCNPJ(initialValues.cnpj);
      setCnpjValue(formatCNPJ(initialValues.cnpj));
      form.setFieldsValue({ cnpj: formattedCnpj });
    }
  }, [initialValues]);

  const handleCnpjChange = (e) => {
    const unformattedValue = e.target.value.replace(/\D+/g, "");
    setCnpjValue(formatCNPJ(unformattedValue));
    const formattedCnpj = formatCNPJ(unformattedValue);
    form.setFieldsValue({ cnpj: formattedCnpj });
  };

  useEffect(() => {
    if (initialValues && initialValues.address) {
      const addressComponents = initialValues.address.split(",");
      const street = addressComponents[0] ? addressComponents[0] : "";
      const neighborhoodAndNumber = addressComponents[1]
        ? addressComponents[1]
        : "";
      const cityAndState = addressComponents[2] ? addressComponents[2] : "";

      const [number, neighborhood] = neighborhoodAndNumber.split("-");
      const [city, state] = cityAndState.split("/");

      form.setFieldsValue({
        _id: initialValues._id && initialValues._id,
        id_superlogica: initialValues.id_superlogica
          ? initialValues.id_superlogica
          : "",
        name: initialValues.name ? initialValues.name : "",
        cnpj: initialValues.cnpj ? initialValues.cnpj : "",
        street: street ? street.trim() : "",
        number: number ? number.trim() : "",
        neighborhood: neighborhood ? neighborhood.trim() : "",
        city: city ? city.trim() : "",
        state: state ? state.trim() : "",
      });
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const fullAddress = `${values.street}, ${values.number} - ${values.neighborhood}, ${values.city}/${values.state}`;
        let formData = {};
        if (initialValues && initialValues.address) {
          formData = {
            _id: initialValues._id,
            name: values.name,
            address: fullAddress,
            id_superlogica: values.id_superlogica,
            cnpj: cnpjValue,
          };
        } else {
          formData = {
            name: values.name,
            id_superlogica: values.id_superlogica,
            address: fullAddress,
            cnpj: cnpjValue,
          };
        }
        onSubmit(formData);
        onClose();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title={initialValues ? "Editar Condomínio" : "Adicionar Novo Condomínio"}
      visible={open}
      onOk={handleSubmit}
      onCancel={(e) => {
        e.stopPropagation();
        onClose();
        !initialValues && form.resetFields();
      }}
      onClick={(e) => e.stopPropagation()}
      okText="Salvar"
      cancelText="Cancelar"
    >
      <Form form={form} layout="vertical" name="condominiumForm">
        <Form.Item name="_id" label="ID">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="id_superlogica"
          label="ID Superlógica"
          rules={[
            { required: true, message: "Por favor, insira o id superlógica!" },
          ]}
        >
          <Input onClick={(e) => e.stopPropagation()} />
        </Form.Item>
        <Form.Item
          name="name"
          label="Nome"
          rules={[{ required: true, message: "Por favor, insira o nome!" }]}
        >
          <Input onClick={(e) => e.stopPropagation()} />
        </Form.Item>
        <Form.Item
          name="cnpj"
          label="CNPJ"
          rules={[{ required: true, message: "Por favor, insira o cnpj!" }]}
        >
          <Input
            value={cnpjValue}
            onChange={handleCnpjChange}
            onClick={(e) => e.stopPropagation()}
          />
        </Form.Item>
        <Form.Item
          name="street"
          label="Rua"
          rules={[{ required: true, message: "Por favor, insira a rua!" }]}
        >
          <Input onClick={(e) => e.stopPropagation()} />
        </Form.Item>
        <Form.Item
          name="number"
          label="Número"
          rules={[{ required: true, message: "Por favor, insira o número!" }]}
        >
          <Input onClick={(e) => e.stopPropagation()} />
        </Form.Item>
        <Form.Item
          name="neighborhood"
          label="Bairro"
          rules={[{ required: true, message: "Por favor, insira o bairro!" }]}
        >
          <Input onClick={(e) => e.stopPropagation()} />
        </Form.Item>
        <Form.Item
          name="city"
          label="Cidade"
          rules={[{ required: true, message: "Por favor, insira a cidade!" }]}
        >
          <Input onClick={(e) => e.stopPropagation()} />
        </Form.Item>
        <Form.Item
          name="state"
          label="Estado"
          rules={[{ required: true, message: "Por favor, insira o estado!" }]}
        >
          <Input onClick={(e) => e.stopPropagation()} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CondominiumModal;
