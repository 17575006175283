import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import AuthLayout from "./hooks/useAuth";
import { UploadProgressProvider } from "./context/uploadProgressContext";
import Profile from "./pages/Profile/Profile";
import { SearchProvider } from "./context/SearchContext";
import { DrawerProvider } from "./context/DrawerContext";
import { FilterProvider } from "./context/FiltersContext";
import { GlobalStyles } from "./components/NavBar/styled";
import Condominiums from "./pages/Condominiums/Condominiums";
import Users from "./pages/Users/Users";
import { DocumentsProvider } from "./context/DocumentsContext";
import { CondoProvider } from "./context/CondoContext";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <GlobalStyles />

        <UploadProgressProvider>
          <CondoProvider>

            <SearchProvider>
              <FilterProvider>
                <BrowserRouter>
                  <DrawerProvider>
                    <DocumentsProvider>
                      <AuthLayout>
                        <Routes>
                          <Route path="/home" element={<Home />} />
                          <Route
                            path="/condominiums"
                            element={<Condominiums />}
                          />
                          <Route path="/users" element={<Users />} />
                          <Route path="/" element={<Login />} />
                          <Route path="/profile" element={<Profile />} />
                        </Routes>
                      </AuthLayout>
                    </DocumentsProvider>
                  </DrawerProvider>
                </BrowserRouter>
              </FilterProvider>
            </SearchProvider>
          </CondoProvider>

        </UploadProgressProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
