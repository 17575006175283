import React, { useContext, useState } from "react";
import {
  Box,
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  AppBar,
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import {
  Menu,
  ChevronLeft,
  Folder,
  Home,
  People,
  Add,
} from "@mui/icons-material";
import Navbar from "../NavBar/NavBar";
import bluelogo from "../../assets/logos/bluelogo.png";
import { useNavigate } from "react-router-dom";
import { useDrawerContext } from "../../context/DrawerContext";
import { AuthContext } from "../../context/AuthContext";
import NewDocumentModal from "../../components/NewDocumentModal/NewDocumentModal";
import axios from "axios";
import { notification } from "antd";
import { useSearch } from "../../context/SearchContext";
import { UploadProgressContext } from "../../context/uploadProgressContext";
import { useDocuments } from "../../context/DocumentsContext";

const drawerWidth = 240;
const miniDrawerWidth = 60;

function ResponsiveDrawer() {
  const theme = useTheme();
  const { drawerExpanded, setDrawerExpanded } = useDrawerContext();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const { selectedCondo } = useSearch();
  const { setUploadProgress } = useContext(UploadProgressContext);
  const { requestDocumentListUpdate } = useDocuments();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const token = localStorage.getItem("token");

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const toggleDrawer = () => {
    if (isDesktop) {
      setDrawerExpanded(!drawerExpanded);
    }
  };

  const handleModalOpen = () => {
    setIsModalVisible(true);
    setMobileOpen(false);
  };

  const handleModalClose = () => setIsModalVisible(false);

  const handleFormSubmit = async (formData, onSuccess, onError, numPages) => {
    let fakeProgress = 0;
    const minDisplayTime = 2000; // Tempo mínimo de exibição do progresso em ms
    const startTime = Date.now();

    // Inicia o progresso artificial
    const updateProgress = setInterval(() => {
      fakeProgress += 5; 
      if (fakeProgress < 90) {
        setUploadProgress(fakeProgress);
      }
    }, 200);
    try {
      const bodyData = { ...formData, numPages };
      const response = await axios.post(
        `${process.env.REACT_APP_API_MAIN}/documents/upload`,
        bodyData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      const elapsedTime = Date.now() - startTime;
      if (elapsedTime < minDisplayTime) {
        setTimeout(() => {
          setUploadProgress(100);
          onSuccess();
        }, minDisplayTime - elapsedTime);
      } else {
        setUploadProgress(100);
        onSuccess();
      }

      notification.success({
        message: "Documento adicionado com sucesso!",
      });
      onSuccess();
      requestDocumentListUpdate();
    } catch (error) {
      if (error.response && error.response.status === 403) {
        notification.error({
          message: "Erro ao adicionar documento.",
          description: `${error.response.data.message}`,
        });
      } else {
        notification.error({
          message: "Error",
          description: "Ocorreu um erro! Por favor, tente novamente.",
        });
      }
      if (onError) onError();
    } finally {
      clearInterval(updateProgress);
      setTimeout(() => {
        setUploadProgress(0);
      }, 500 + minDisplayTime - (Date.now() - startTime));
    }
  };

  const drawer = (
    <div>
      <Toolbar>
        {isDesktop && (
          <IconButton onClick={toggleDrawer}>
            {drawerExpanded ? (
              <ChevronLeft style={{ color: "#FFFBF0" }} />
            ) : (
              <Menu
                style={{
                  color: "#FFFBF0",
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: "-100%",
                }}
              />
            )}
          </IconButton>
        )}
        {drawerExpanded && (
          <img
            src={bluelogo}
            alt="Logo"
            width={100}
            style={{ marginLeft: 10 }}
          />
        )}
      </Toolbar>
      <Divider />
      <Button
        startIcon={<Add />}
        variant="contained"
        style={{
          background: "#1b7884",
          height: "50px",
          width: "80%",
          marginLeft: "10px",
          marginTop: "10px",
          marginBottom: "10px",
        }}
        onClick={handleModalOpen}
      >
        {drawerExpanded && "Novo"}
      </Button>
      <Divider />
      <List>
        {["Documentos", user?.isMaster ? "Condomínios" : null, "Usuários"]
          .filter(Boolean)
          .map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  switch (text) {
                    case "Documentos":
                      handleNavigation("/home");
                      break;
                    case "Condomínios":
                      handleNavigation("/condominiums");
                      break;
                    case "Usuários":
                      handleNavigation("/users");
                      break;
                    default:
                      break;
                  }
                }}
              >
                <ListItemIcon>
                  {text === "Documentos" && (
                    <Folder style={{ color: "#FFFBF0" }} />
                  )}
                  {text === "Condomínios" && (
                    <Home style={{ color: "#FFFBF0" }} />
                  )}
                  {text === "Usuários" && (
                    <People style={{ color: "#FFFBF0" }} />
                  )}
                </ListItemIcon>
                {drawerExpanded && <ListItemText primary={text} />}
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </div>
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: {
              sm: `calc(100% - ${
                drawerExpanded ? drawerWidth : miniDrawerWidth
              }px)`,
            },
            ml: { sm: `${drawerExpanded ? drawerWidth : miniDrawerWidth}px` },
          }}
        >
          <Navbar handleDrawerToggle={handleDrawerToggle} />
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            PaperProps={{
              sx: {
                backgroundColor: "#1B6684",
                color: "#FFFBF0",
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerExpanded ? drawerWidth : miniDrawerWidth,
              },
            }}
            PaperProps={{
              sx: {
                backgroundColor: "#1B6684",
                color: "#FFFBF0",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      <NewDocumentModal
        isVisible={isModalVisible}
        onClose={handleModalClose}
        onSubmit={(formData, onSuccess, onError, numPages) =>
          handleFormSubmit(formData, onSuccess, onError, numPages)
        }
        condominium={selectedCondo}
        user={user}
      />
    </>
  );
}

export default ResponsiveDrawer;
